import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { styled } from "goober";

import { readPath } from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/constants.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import { useOverlaySettings } from "@/library/actions.mjs";

const SimulatedSummonerSpellTimerOverlay = () => {
  const settings = useOverlaySettings(GAME_SYMBOL_LOL);

  const displayOption = useMemo(() => {
    if (!settings) return;

    const isEnabled = readPath(
      ["isSummonerSpellTrackerOverlayEnabled"],
      settings,
    );
    const isAlwaysInteractive: boolean = readPath(
      ["summonerSpellTracker", "isAlwaysInteractive"],
      settings,
    );
    const toggleKey: string = readPath(
      ["summonerSpellTracker", "toggleKey"],
      settings,
    )?.value;

    return {
      isEnabled,
      isAlwaysInteractive,
      toggleKey,
    };
  }, [settings]);

  return (
    <OverlayContainer>
      {displayOption.isEnabled ? (
        <>
          <img
            src={`${appURLs.CDN}/blitz/ui/img/settings/summonerSpellTrackerOverlay/summoner-spell-tracker.webp`}
            alt="Summoner Spell Tracker"
          />
          {!displayOption.isAlwaysInteractive && (
            <p className="type-caption">
              <Trans
                i18nKey="overlays:lol.summonerSpellTracker.holdToInteract"
                defaults="Hold <span>{{hotkey}}</span> to interact"
                values={{
                  hotkey: displayOption.toggleKey,
                }}
                components={{
                  span: <span className="type-caption--semi hotkey" />,
                }}
              />
            </p>
          )}
        </>
      ) : null}
    </OverlayContainer>
  );
};

export default SimulatedSummonerSpellTimerOverlay;

const OverlayContainer = styled("div")`
  padding: var(--sp-5) 0;

  img {
    width: 20vh;
  }

  p {
    padding-left: var(--sp-2);

    color: rgba(255, 255, 255, 0.5);
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);

    .hotkey {
      color: var(--white);
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
    }
  }
`;
